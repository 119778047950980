import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '~/theme';
import { DrawerNotificationsProvider } from '~/state/DrawerNotificationsProvider';
import { PopUpDisclaimerProvider } from '~/state/PopUpDisclaimerProvider';
import { HelmetProvider } from 'react-helmet-async';
import { KeycloakProvider } from '~/state/KeycloakProvider';
import { KycRouter } from '~/pages/KycRouter';
import { LocalisationProvider } from '~/state/LocalisationProvider';
import { CustomProductFruits } from './state/CustomProductFruits';
import { UiContextProvider } from '~/state/UiContextProvider';

export default function KycApp() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UiContextProvider>
          <LocalisationProvider>
            <KeycloakProvider>
              <CustomProductFruits />
              <PopUpDisclaimerProvider>
                <DrawerNotificationsProvider>
                  <KycRouter />
                </DrawerNotificationsProvider>
              </PopUpDisclaimerProvider>
            </KeycloakProvider>
          </LocalisationProvider>
        </UiContextProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}
