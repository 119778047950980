import { useCallback } from 'react';
import { getPathWithReplacedLocale } from '~/util/i18next';
import { patchMeInfo } from '~/services/KycService';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useCookies } from '~/util/useCookies';

export function useSetLocale() {
  const navigate = useNavigate();
  const { setCookie } = useCookies();

  return useCallback(
    (lang: string) => {
      // change just the locale and maintain all other route information including href's query
      //This will reset the confirmedOn field and the popup will open when the user switches language
      patchMeInfo({ language: lang.toLocaleUpperCase() });
      const newPath = getPathWithReplacedLocale(lang);
      window?.productFruits?.services?.destroy?.();
      setCookie('NEXT_LOCALE', lang, 24 * 365);
      navigate(newPath);
      i18next.changeLanguage(lang);
    },
    [navigate, setCookie],
  );
}
