import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from '~/util/i18next';
import { AboutKyc } from '~/components/organisms/AboutKyc';
import { getMeInfo, patchMeInfo } from '~/services/KycService';

interface PopUpDisclaimerData {
  showPopUp: () => void;
  hidePopUp: () => void;
}

export const PopUpDisclaimerContext = createContext<PopUpDisclaimerData>({} as PopUpDisclaimerData);

export function PopUpDisclaimerProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const showPopUp = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const hidePopUp = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    async function getUserInfo() {
      const response = await getMeInfo();
      if (response?.status === 200) {
        return response.data;
      }
    }

    getUserInfo()
      .then((response) => {
        if (
          !response.confirmedOn ||
          new Date(response.confirmedOn).getTime() < new Date(t('disclaimer.datekey')).getTime()
        ) {
          showPopUp();
        }
      })
      .catch((err) => console.error(err));
  }, [showPopUp, t]);

  function onConfirmAboutKycModal(isChecked: boolean) {
    if (isChecked) {
      const dateNow = new Date();
      const dateNowUtc = Date.UTC(
        dateNow.getUTCFullYear(),
        dateNow.getUTCMonth(),
        dateNow.getUTCDate(),
        dateNow.getUTCHours(),
        dateNow.getUTCMinutes(),
        dateNow.getUTCSeconds(),
      );
      patchMeInfo({
        confirmedOn: new Date(dateNowUtc).toISOString(),
      });
    }
    hidePopUp();
  }

  return (
    <PopUpDisclaimerContext.Provider
      value={{
        showPopUp,
        hidePopUp,
      }}
    >
      <AboutKyc isOpen={isOpen} handleClose={hidePopUp} onConfirm={onConfirmAboutKycModal} />
      {children}
    </PopUpDisclaimerContext.Provider>
  );
}

export const usePopUpDisclaimer = () => useContext(PopUpDisclaimerContext);
